import React from 'react';
import './App.css';
import { Route } from 'react-router-dom'
import Header from './Components/header.js';
import Footer from './Components/footer.js';
import Home from './Components/home.js';
import Pets from './Components/pets.js';
import Cats from './Components/cats.js';
import Dogs from './Components/dogs.js';
import About from './Components/about.js';
import Calendars from './Components/calendar.js';
import Buildings from './Components/buildings.js';
import Contact from './Components/contact.js';
import Others from './Components/others.js';
import Horses from './Components/horses.js';
/*
"dev": "react-scripts start",
"start": "serve -s build",
"build": "react-scripts build",
"test": "react-scripts test --env=jsdom",
"eject": "react-scripts eject",
"heroku-postbuild": "npm run build"
*/

class App extends React.Component {
  render() {
    return(
      <div className="App">
        <Header/>
        
        {/* home page */}
        <Route exact path={`/`} render={() => {
          return(
            <div>
              <Home/>
              <Footer/>
            </div>
          )
        }} />

        {/* about page */}
        <Route path={`/about`} render={() => {
          return(
            <div>
              <About/>
              <Footer/>
            </div>
          )
        }} />

        {/* pets page */}
        <Route path={'/pets'} render={() => {
          return(
            <div>
              <Pets/>
              <Footer/>
            </div>
          )
        }}/> 


        <Route path={'/cats'} render={()=>{
          return(
            <div>
              <Cats/>
              <Footer/>
            </div>
          )
        }}/>

        <Route path={'/dogs'} render={()=>{
          return(
            <div>
              <Dogs/>
              <Footer/>
            </div>
          )
        }}/>

        <Route path={'/calendars'} render={()=>{
          return(
            <div>
              <Calendars/>
              <Footer/>
            </div>
          )
        }}/>

        <Route path={'/contact'} render={()=>{
          return(
            <div>
              <Contact/>
              <Footer/>
            </div>
          )
        }}/>

        <Route path={'/buildings'} render={()=>{
          return(
            <div>
              <Buildings/>
              <Footer/>
            </div>
          )
        }} />

        <Route path={'/horses'} render={()=>{
          return(
            <div>
              <Horses/>
              <Footer/>
            </div>
          )
        }} />

        <Route path={'/others'} render={()=>{
          return(
            <div>
              <Others/>
              <Footer/>
            </div>
          )
        }} />

    </div>
    )
  }
}
export default App;
