import React from 'react';
import FB from '../Images/Icons/facebook.png';
import IG from '../Images/Icons/insta.png';

class Footer extends React.Component {
    render(){
        return(
            <div>
                <div class="hidden-xs" id='footer'>
                    <div style={{padding:'20px',float:'left'}}>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/mitamaria.gironda.3"><img style={{padding:'15px',width:'75px'}} alt={'facebook'} src={FB} /></a>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/mita_gironda"><img style={{padding:'15px',width:'75px'}} alt={'instagram'} src={IG} /></a>
                        <h4 class="visible-xs" style={{fontWeight:'600'}}>Created By <a style={{color:'black',fontWeight:'bold',textDecoration:"none"}} href={"https://www.emilybolten.com/"} rel="noopener noreferrer" target="_blank">Emily Bolten</a></h4>
                    </div>
                    <div class="hidden-xs">
                        <br/><br/><br/>
                        <h4 style={{paddingTop:'20px',paddingRight:'10px',float:'right',fontWeight:'600'}}>Created By <a style={{color:'black',textDecoration:"none"}} href={"https://www.emilybolten.com/"} rel="noopener noreferrer" target="_blank">Emily Bolten</a></h4>
                    </div>
                </div>
                {/* mobile footer text */}
                <div class="visible-xs" id='footer-sm'>
                    <div style={{padding:'20px'}}>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/mitamaria.gironda.3"><img style={{padding:'15px',width:'75px'}} alt={'facebook'} src={FB} /></a>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/mita_gironda"><img style={{padding:'15px',width:'75px'}} alt={'instagram'} src={IG} /></a>
                        <h4 style={{marginBottom:'0px',fontWeight:'600'}}>Website developed by <a style={{color:'black',fontWeight:'bold',textDecoration:"none"}} href={"https://www.emilybolten.com/"} rel="noopener noreferrer" target="_blank">Emily Bolten</a></h4>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer;
