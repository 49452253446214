import React from 'react';
import Building1 from '../Images/Buildings/building13.jpeg';
import Building2 from '../Images/Buildings/building1.jpeg';
import Building3 from '../Images/Buildings/building2.jpeg';
import Building4 from '../Images/Buildings/building3.jpeg';
import Building5 from '../Images/Buildings/building4.jpeg';
import Building6 from '../Images/Buildings/building5.jpeg';
import Building7 from '../Images/Buildings/building6.jpeg';
import Building8 from '../Images/Buildings/building7.jpeg';
import Building9 from '../Images/Buildings/building8.jpeg';
import Building10 from '../Images/Buildings/building9.jpeg';
import Building11 from '../Images/Buildings/building10.jpeg';
import Building12 from '../Images/Buildings/building11.jpeg';

class Buildings extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden'}}>
                <div style={{width:'100%'}}>
                <br/>
                {/*<h1 style={{fontSize:'xxx-large',fontWeight:'800'}}> SEE BUILDINGS AND LANDSCAPES </h1>
                <h3 style={{textAlign:'center',fontWeight:'lighter'}}> Paintings Available Upon Request. See Contact Info on the Homepage. </h3>*/}
                    <div className="row">
                        <div className="column">
                            <img alt={"Building"} src={Building4}/>
                            <img alt={"Building"} src={Building8}/>
                            <img alt={"Building"} src={Building12}/>
                        </div>
                        <div className="column">
                            <img alt={"Building"} src={Building2}/>
                            <img alt={"Building"} src={Building1}/>
                            <img alt={"Building"} src={Building9}/>
                        </div>
                        <div className="column">
                            <img alt={"Building"} src={Building6}/>
                            <img alt={"Building"} src={Building3}/>
                            <img alt={"Building"} src={Building10}/>
                        </div>
                        <div className="column">
                            <img alt={"Building"} src={Building5}/>
                            <img alt={"Building"} src={Building7}/>
                            <img alt={"Building"} src={Building11}/>
                        </div>
                    </div>
                    <br/><br/>
                </div>
            </div>
        )
    }
}

export default Buildings;
