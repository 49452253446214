import React from 'react';
import Card1 from '../Images/Icons/buisinessCard1.jpeg';
import Card2 from '../Images/Icons/buisinessCard2.jpeg';
import Mita from '../Images/Icons/profile.jpeg';

class Home extends React.Component {
    render(){
        return(
            <div>
                {/* PC homepage */}
                <div class="hidden-xs">
                    {/* contact */}
                    <br/><br/>
                    <h1 style={{fontSize:'xxx-large',fontWeight:'800',textAlign:'center'}}> CONTACT MITA </h1>
                    <div style={{overflowX:'hidden',width:'100%'}} className="row">
                        <div className="column">
                            <br/><br/><br/><br/>
                            <h1>Mita Gironda</h1>
                            <h3 style={{padding:'5px',fontWeight:'lighter'}}>
                                Pet portraits or landscapes on request.
                                You send Mita a picture and she will paint it.
                                Requested paintings will be ready in two weeks.
                            </h3>
                            <h3 style={{padding:'5px',fontWeight:'lighter'}}>
                                Delivery to everywhere in the world.
                            </h3>
                        </div>
                        <div className="column">
                            <img alt={'Card'} style={{height:'475px',width:'auto',padding:'20px'}} src={Card1}/>
                        </div>
                        <div className="column">
                            <img alt={'Card'} style={{height:'475px',width:'auto',padding:'20px'}} src={Card2}/>
                        </div>
                        <div className="column">
                            <br/><br/><br/><br/>
                            <h1>Contact Information</h1>
                            <h3 style={{fontWeight:'lighter'}}><b>Telephone:</b>
                            <br/>
                            (301) 500-7494
                            <br/><br/>
                            <b>Email:</b>
                            <br/>
                            <h3 style={{fontWeight:'lighter'}}>mitamariagironda@icloud.com</h3>
                            </h3>
                        </div>
                    </div>
                    {/* image and about */}
                    <br/><br/>
                    <div className="row">
                        <div className='column'></div>
                            <div className='column'>
                                <img alt={'mita'} src={Mita}/>
                            </div>
                            <div className='column'>
                                <br/>
                                <h3 style={{fontWeight:"lighter",padding:'5px'}}>
                                    Mita draws any pets and landscape or homes on commission 
                                    using soft pastels. You send the picture you love and she will deliver to you the paintings two weeks later anywhere in the world 
                                    in the sizes that you choose.
                                </h3>
                            </div>
                        <div className='column'></div>
                    </div>

                    <br/><br/>
                </div>

                {/* mobile homepage */}
                <div className='visible-xs'>
                    {/*<h1 style={{fontSize:'xxx-large',fontWeight:'800',textAlign:'center'}}> CONTACT MITA </h1>*/}
                    <div style={{overflowX:'hidden',width:'100%'}} className="row">
                        <div className="column">
                            <br/><br/><br/><br/>
                            <h1 style={{margin:'0px'}}>Mita Gironda</h1>

                            <h3 style={{margin:'15px',fontWeight:'lighter'}}>
                                Mita draws any pets and landscape or homes on commission 
                                using soft pastels. You send the picture you love and she will deliver to you the paintings two weeks later anywhere in the world 
                                in the sizes that you choose.
                            </h3>
                            <img style={{margin:'15px'}} src={Mita} alt={"mita"}/>
                            
                            {/*<h3 style={{padding:'5px',fontWeight:'lighter'}}>
                                Delivery to everywhere in the world.
                            </h3>*/}
                        </div>
                        <div className="column">
                            <img alt={'Card'} style={{height:'475px',width:'auto',padding:'20px'}} src={Card1}/>
                        </div>
                        <div className="column">
                            <img alt={'Card'} style={{height:'475px',width:'auto',padding:'20px'}} src={Card2}/>
                        </div>
                        <div className="column">
                            <br/><br/><br/><br/>
                            <h1>Contact Information</h1>
                            <h3 style={{fontWeight:'lighter'}}><b>Telephone:</b>
                            <br/>
                            (301) 500-7494
                            <br/><br/>
                            <b>Email:</b>
                            <br/>
                            <h3 style={{fontWeight:'lighter'}}>mitamariagironda@icloud.com</h3>
                            </h3>
                        </div>
                    </div>
                </div>


            </div>
            
        )
    }
}
export default Home;