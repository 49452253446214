import React from 'react';
import Cat1 from '../Images/Cats/cat1.jpeg';
import Cat2 from '../Images/Cats/cat3.jpeg';
import Cat3 from '../Images/Cats/cat2.jpeg';
import Cat4 from '../Images/Cats/cat4.jpeg';
import Cat5 from '../Images/Cats/cat5.jpeg';
import Cat6 from '../Images/Cats/cat6.jpeg';
import Cat7 from '../Images/Cats/cat7.jpeg';
import Dog1 from '../Images/Dogs/dog2.jpeg';
import Dog2 from '../Images/Dogs/dog3.jpeg';
import Dog3 from '../Images/Dogs/dog4.jpeg';
import Dog4 from '../Images/Dogs/dog5.jpeg';
import Dog5 from '../Images/Dogs/dog1.jpeg';
import Dog6 from '../Images/Dogs/dog6.jpeg';
import Dog7 from '../Images/Dogs/dog7.jpeg';
import Dog8 from '../Images/Dogs/dog8.jpeg';

class Pets extends React.Component {
    render(){
        return(
            <div>
                <h1>Cat Portraits</h1>
                <div className="row">
                    <div className="column">
                        <img alt={"Cat"} src={Cat3}/>
                        <img alt={"Cat"} src={Cat4}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat2}/>
                        <img alt={"Cat"} src={Cat1}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat5}/>
                        <img alt={"Cat"} src={Cat6}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat6}/>
                        <img alt={"Cat"} src={Cat7}/>
                    </div>
                </div>
                <br/><br/>

                <div id="dogs"></div>
                <br/><br/><br/><br/>
                <h1>Dog Portraits</h1>
                <div className="row">
                    <div className="column">
                        <img alt={"Dog"} src={Dog2}/>
                        <img alt={"Dog"} src={Dog4}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog3}/>
                        <img alt={"Dog"} src={Dog1}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog5}/>
                        <img alt={"Dog"} src={Dog6}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog7}/>
                        <img alt={"Dog"} src={Dog8}/>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default Pets;