import React from 'react';

// will toggle dropdown menu
let dropdownOpen = false;

// toggle the dropdown
function toggleMenu() {
    dropdownOpen = !dropdownOpen;
    let dropdownMenu = document.getElementById('dropdown');
    if (dropdownOpen) {
        dropdownMenu.style.display = 'block';
    } else {
        dropdownMenu.style.display = 'none';
    }
}

// dropdown menu will show for smaller screen sizes
class DropdownNav extends React.Component {
    render() {
        return(
            <div>
                <div id="dropdown-container">
                    {/* dropdown menu toggle button */}
                    <button onClick={toggleMenu} type="button" id="dropdown-btn">☰</button>    
    
                    {/* dropdown menu display */}
                    <div style={{display:'none'}} id="dropdown">
                        <ul>
                            <li><a style={{textDecoration:'none'}} href="/"><span id="menu-item-xs">Home</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/cats"><span id="menu-item-xs">Cats</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/dogs"><span id="menu-item-xs">Dogs</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/horses"><span id="menu-item-xs">Horses</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/buildings"><span id="menu-item-xs">Buildings and Landscapes</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/others"><span id="menu-item-xs">Others</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/calendars"><span id="menu-item-xs">Calendars</span></a></li>
                            <li><a style={{textDecoration:'none'}} href="/contact"><span id="menu-item-xs">Contact</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default DropdownNav;