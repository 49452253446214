import React from 'react';
import Dog1 from '../Images/Dogs/dog2.jpeg';
import Dog2 from '../Images/Dogs/dog3.jpeg';
import Dog3 from '../Images/Dogs/dog4.jpeg';
import Dog4 from '../Images/Dogs/dog5.jpeg';
import Dog5 from '../Images/Dogs/dog1.jpeg';
import Dog6 from '../Images/Dogs/dog24.jpeg';
import Dog8 from '../Images/Dogs/dog15.jpeg';
import Dog9 from '../Images/Dogs/dog10.jpeg';
import Dog12 from '../Images/Dogs/dog18.jpeg';
import Dog13 from '../Images/Dogs/dog19.jpeg';
import Dog15 from '../Images/Dogs/dog28.jpeg';
import Dog17 from '../Images/Dogs/dog21.jpeg';
import Dog18 from '../Images/Dogs/dog25.jpeg';
import Dog19 from '../Images/Dogs/dog16.jpeg';
import Dog20 from '../Images/Dogs/dog8.jpeg';
import Dog23 from '../Images/Dogs/dog9.jpeg';
import Dog24 from '../Images/Dogs/dog14.jpeg';
import Dog25 from '../Images/Dogs/dog13.jpeg';
import Dog26 from '../Images/Dogs/dog12.jpeg';
import Dog27 from '../Images/Dogs/dog11.jpeg';
import Dog28 from '../Images/Dogs/dog17.jpeg';
import Dog29 from '../Images/Dogs/dog20.jpeg';
import Dog30 from '../Images/Dogs/dog23.jpeg';
import Dog31 from '../Images/Dogs/dog22.jpeg';
import Dog32 from '../Images/Dogs/dog26.jpeg';
import Dog33 from '../Images/Dogs/dog27.jpeg';
import Dog34 from '../Images/Dogs/dog29.jpeg';
import Dog35 from '../Images/Dogs/dog30.jpeg';
import Dog36 from '../Images/Dogs/dog31.jpeg';
import Dog37 from '../Images/Dogs/dog32.jpeg';
import Dog38 from '../Images/Dogs/dog33.jpeg';
import Dog39 from '../Images/Dogs/dog34.jpeg';
import Dog40 from '../Images/Dogs/dog35.jpeg';
import Dog41 from '../Images/Dogs/dog36.jpeg';
import Dog42 from '../Images/Dogs/dog37.jpeg';
import Dog43 from '../Images/Dogs/dog38.jpeg';
import Dog44 from '../Images/Dogs/dog39.jpeg';
import Dog45 from '../Images/Dogs/dog40.jpeg';
import Dog46 from '../Images/Dogs/dog41.jpeg';
import Dog47 from '../Images/Dogs/dog42.jpeg';
import Dog48 from '../Images/Dogs/dog43.jpeg';
import Dog49 from '../Images/Dogs/dog44.jpeg';
import Dog50 from '../Images/Dogs/dog45.jpeg';
import Dog51 from '../Images/Dogs/dog46.jpeg';
import Dog52 from '../Images/Dogs/dog47.jpeg';
import Dog53 from '../Images/Dogs/dog48.jpeg';
import Dog54 from '../Images/Dogs/dog49.jpeg';
import Dog55 from '../Images/Dogs/dog50.jpeg';
import Dog56 from '../Images/Dogs/dog51.jpeg';
import Dog57 from '../Images/Dogs/dog52.jpeg';
import Dog58 from '../Images/Dogs/dog53.jpeg';
import Dog59 from '../Images/Dogs/dog54.jpeg';
import Dog60 from '../Images/Dogs/dog55.jpeg';
import Dog61 from '../Images/Dogs/dog56.jpeg';
import Dog62 from '../Images/Dogs/dog57.jpeg';
import Dog63 from '../Images/Dogs/dog58.jpeg';
import Dog64 from '../Images/Dogs/dog62.jpeg';
import Dog65 from '../Images/Dogs/dog63.jpeg';
import Dog66 from '../Images/Dogs/dog64.jpeg';
import Dog67 from '../Images/Dogs/dog65.jpeg';
import Dog68 from '../Images/Dogs/dog66.jpeg';
import Dog69 from '../Images/Dogs/dog67.jpeg';
import Dog70 from '../Images/Dogs/dog68.jpeg';
import Dog71 from '../Images/Dogs/dog69.jpeg';
import Dog72 from '../Images/Dogs/dog70.jpeg';
import Dog73 from '../Images/Dogs/dog71.jpeg';
import Dog74 from '../Images/Dogs/dog72.jpeg';
import Dog75 from '../Images/Dogs/dog73.jpeg';
import Dog76 from '../Images/Dogs/dog74.jpeg';
import Dog77 from '../Images/Dogs/dog75.jpeg';
import Dog78 from '../Images/Dogs/dog76.jpeg';
import Dog79 from '../Images/Dogs/dog77.jpeg';
import Dog80 from '../Images/Dogs/dog78.jpeg';
import Dog81 from '../Images/Dogs/dog79.jpeg';
import Dog82 from '../Images/Dogs/dog80.jpeg';
import Dog83 from '../Images/Dogs/dog81.jpeg';
import Dog84 from '../Images/Dogs/dog82.jpeg';
import Dog85 from '../Images/Dogs/dog83.jpeg';
import Dog86 from '../Images/Dogs/dog84.jpeg';
import Dog87 from '../Images/Dogs/dog85.jpeg';
import Dog88 from '../Images/Dogs/dog86.jpeg';
import Dog89 from '../Images/Dogs/dog87.jpeg';
import Dog90 from '../Images/Dogs/dog88.jpeg';
import Dog91 from '../Images/Dogs/dog89.jpeg';
import Dog92 from '../Images/Dogs/dog90.jpeg';
import Dog93 from '../Images/Dogs/dog91.jpeg';
import Dog94 from '../Images/Dogs/dog92.jpeg';
import Dog95 from '../Images/Dogs/dog93.jpeg';
import Dog96 from '../Images/Dogs/dog94.jpeg';
import Dog97 from '../Images/Dogs/dog95.jpeg';
import Dog98 from '../Images/Dogs/dog96.jpeg';
import Dog99 from '../Images/Dogs/dog97.jpeg';

class Dogs extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden'}}>
            <br/>
            {/*<h1 style={{fontSize:'xxx-large',fontWeight:'800'}}> SEE DOG PORTRAITS </h1>
            <h3 style={{textAlign:'center',fontWeight:'lighter'}}> Paintings Available Upon Request. See Contact Info on the Homepage. </h3>*/}
                <div className="row">
                    <div className="column">
                        <img alt={"Dog"} src={Dog3}/>
                        <img alt={"Dog"} src={Dog4}/>
                        <img alt={"Dog"} src={Dog12}/>
                        <img alt={"Dog"} src={Dog8}/>
                        <img alt={"Dog"} src={Dog20}/>
                        <img alt={"Dog"} src={Dog34}/>
                        <img alt={"Dog"} src={Dog35}/>
                        <img alt={"Dog"} src={Dog32}/>
                        <img alt={"Dog"} src={Dog41}/>
                        <img alt={"Dog"} src={Dog44}/>
                        <img alt={"Dog"} src={Dog46}/>
                        <img alt={"Dog"} src={Dog50}/>
                        <img alt={"Dog"} src={Dog54}/>
                        <img alt={"Dog"} src={Dog58}/>
                        <img alt={"Dog"} src={Dog62}/>
                        <img alt={"Dog"} src={Dog67}/>
                        <img alt={"Dog"} src={Dog71}/>
                        <img alt={"Dog"} src={Dog76}/>
                        <img alt={"Dog"} src={Dog80}/>
                        <img alt={"Dog"} src={Dog84}/>
                        <img alt={"Dog"} src={Dog89}/>
                        <img alt={"Dog"} src={Dog93}/>
                        <img alt={"Dog"} src={Dog96}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog2}/>
                        <img alt={"Dog"} src={Dog17}/>
                        <img alt={"Dog"} src={Dog1}/>
                        <img alt={"Dog"} src={Dog24}/>
                        <img alt={"Dog"} src={Dog25}/>
                        <img alt={"Dog"} src={Dog36}/>
                        <img alt={"Dog"} src={Dog37}/>
                        <img alt={"Dog"} src={Dog39}/>
                        <img alt={"Dog"} src={Dog45}/>
                        <img alt={"Dog"} src={Dog47}/>
                        <img alt={"Dog"} src={Dog51}/>
                        <img alt={"Dog"} src={Dog55}/>
                        <img alt={"Dog"} src={Dog59}/>
                        <img alt={"Dog"} src={Dog63}/>
                        <img alt={"Dog"} src={Dog66}/>
                        <img alt={"Dog"} src={Dog69}/>
                        <img alt={"Dog"} src={Dog74}/>
                        <img alt={"Dog"} src={Dog78}/>
                        <img alt={"Dog"} src={Dog81}/>
                        <img alt={"Dog"} src={Dog85}/>
                        <img alt={"Dog"} src={Dog88}/>
                        <img alt={"Dog"} src={Dog92}/>
                        <img alt={"Dog"} src={Dog97}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog5}/>
                        <img alt={"Dog"} src={Dog18}/>
                        <img alt={"Dog"} src={Dog9}/>
                        <img alt={"Dog"} src={Dog26}/>
                        <img alt={"Dog"} src={Dog27}/>
                        <img alt={"Dog"} src={Dog28}/>
                        <img alt={"Dog"} src={Dog29}/>
                        <img alt={"Dog"} src={Dog38}/>
                        <img alt={"Dog"} src={Dog42}/>
                        <img alt={"Dog"} src={Dog48}/>
                        <img alt={"Dog"} src={Dog52}/>
                        <img alt={"Dog"} src={Dog56}/>
                        <img alt={"Dog"} src={Dog60}/>
                        <img alt={"Dog"} src={Dog64}/>
                        <img alt={"Dog"} src={Dog68}/>
                        <img alt={"Dog"} src={Dog72}/>
                        <img alt={"Dog"} src={Dog75}/>
                        <img alt={"Dog"} src={Dog79}/>
                        <img alt={"Dog"} src={Dog83}/>
                        <img alt={"Dog"} src={Dog87}/>
                        <img alt={"Dog"} src={Dog91}/>
                        <img alt={"Dog"} src={Dog94}/>
                        <img alt={"Dog"} src={Dog98}/>
                        <img alt={"Dog"} src={Dog99}/>
                    </div>
                    <div className="column">
                        <img alt={"Dog"} src={Dog6}/>
                        <img alt={"Dog"} src={Dog13}/>
                        <img alt={"Dog"} src={Dog19}/>
                        <img alt={"Dog"} src={Dog15}/>
                        <img alt={"Dog"} src={Dog30}/>
                        <img alt={"Dog"} src={Dog31}/>
                        <img alt={"Dog"} src={Dog23}/>
                        <img alt={"Dog"} src={Dog33}/>
                        <img alt={"Dog"} src={Dog40}/>
                        <img alt={"Dog"} src={Dog43}/>
                        <img alt={"Dog"} src={Dog49}/>
                        <img alt={"Dog"} src={Dog53}/>
                        <img alt={"Dog"} src={Dog57}/>
                        <img alt={"Dog"} src={Dog61}/>
                        <img alt={"Dog"} src={Dog65}/>
                        <img alt={"Dog"} src={Dog70}/>
                        <img alt={"Dog"} src={Dog73}/>
                        <img alt={"Dog"} src={Dog77}/>
                        <img alt={"Dog"} src={Dog82}/>
                        <img alt={"Dog"} src={Dog86}/>
                        <img alt={"Dog"} src={Dog90}/>
                        <img alt={"Dog"} src={Dog95}/>
                    </div>
                </div>
                <br/><br/>
            </div>
        )
    }
}

export default Dogs;
