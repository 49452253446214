import React from "react";
import Calendar1 from '../Images/Calendars/calendar1.gif';
import Calendar2 from '../Images/Calendars/calendar2.gif';
import Calendar3 from '../Images/Calendars/calendar3.gif';
import Calendar4 from '../Images/Calendars/calendar4.gif';

class Calendar extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden'}}>
                {/* calendars */}
                <div className="row">
                    <div className="column visible-xs">
                        <h1>Calendars</h1>
                        <h3 style={{fontWeight:'lighter'}}>
                            Use the contact information above to purchase
                            a calendar with paintings on it.
                        </h3>
                    </div>
                    <div className="column">
                        <img alt={"Calendar"} src={Calendar1}/>
                    </div>
                    <div className="column">
                        <img alt={"Calendar"} src={Calendar2}/>
                    </div>
                    <div className="column hidden-xs">
                        <h1>Calendars</h1>
                        <h3 style={{fontWeight:'lighter'}}>
                            Use the contact information below to purchase a calendar
                             that features some of Mita's paintings.
                            <br/><br/><br/>
                            <h1>Contact Information</h1>
                            <h3 style={{fontWeight:'lighter'}}>
                            <b>Telephone:</b>
                            <br/>
                            (301) 500-7494
                            <br/><br/>
                            <b>Email:</b>
                            <br/>
                            <h3 style={{fontWeight:'lighter'}}>mitamariagironda@icloud.com</h3>
                        </h3>
                        </h3>
                    </div>
                    <div className="column">
                        <img alt={"Calendar"} src={Calendar3}/>
                    </div>
                    <div className="column visible-xs">
                        <img alt={"Calendar"} src={Calendar4}/>
                    </div>
                </div>
                <br/><br/><br/>

            </div>
        )
    }
}

export default Calendar;