import React from 'react';
import Horse1 from '../Images/Horses/horse1.jpeg';
import Horse2 from '../Images/Horses/horse3.jpeg';
import Horse3 from '../Images/Horses/horse4.jpeg';
import Horse4 from '../Images/Horses/horse7.jpeg';

class Horses extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden'}}>
                <div style={{width:'100%'}}>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img alt={"Horse"} src={Horse1}/>
                        </div>
                        <div className="column">
                            <img alt={"Horse"} src={Horse2}/>
                        </div>
                        <div className="column">
                            <img alt={"Horse"} src={Horse3}/>
                        </div>
                        <div className="column">
                            <img alt={"Horse"} src={Horse4}/>
                        </div>
                    </div>
                    <br/><br/>
                </div>
            </div>
        )
    }
}

export default Horses;
