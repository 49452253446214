import React from 'react';
import Other1 from '../Images/Others/other1.jpeg';
import Other2 from '../Images/Others/other2.jpeg';
import Other3 from '../Images/Others/other3.jpeg';
import Other4 from '../Images/Others/other4.jpeg';
import Other5 from '../Images/Others/other5.jpeg';
import Other6 from '../Images/Others/other6.jpeg';
import Other7 from '../Images/Others/other8.jpeg';
import Other8 from '../Images/Others/other9.jpeg';
import Other9 from '../Images/Others/other10.jpeg';
import Other10 from '../Images/Others/other11.jpeg';
import Other11 from '../Images/Others/other12.jpeg';
import Other12 from '../Images/Others/other13.jpeg';
import Other13 from '../Images/Others/other15.jpeg';
import Other14 from '../Images/Others/other14.jpeg';
import Other15 from '../Images/Others/other16.jpeg';

class Others extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden',width:'100%'}}>

                <div>
                <br/>
                    <div className="row">
                        <div className="column">
                            <img alt={"Other"} src={Other3}/>
                            <img alt={"Other"} src={Other4}/>
                            <img alt={"Other"} src={Other8}/>
                            <img alt={"Other"} src={Other15}/>
                        </div>
                        <div className="column">
                            <img alt={"Other"} src={Other2}/>
                            <img alt={"Other"} src={Other7}/>
                            <img alt={"Other"} src={Other1}/>
                            <img alt={"Other"} src={Other5}/>
                        </div>
                        <div className="column">
                            <img alt={"Other"} src={Other10}/>
                            <img alt={"Other"} src={Other9}/>
                            <img alt={"Other"} src={Other13}/>
                        </div>
                        <div className="column">
                            <img alt={"Other"} src={Other6}/>
                            <img alt={"Other"} src={Other11}/>
                            <img alt={"Other"} src={Other12}/>
                            <img alt={"Other"} src={Other14}/>
                        </div>
                    </div>
                    <br/><br/>
                </div>

            </div>
        )
    }
}

export default Others;
