import React from 'react';
import Cat1 from '../Images/Cats/cat1.jpeg';
import Cat2 from '../Images/Cats/cat3.jpeg';
import Cat3 from '../Images/Cats/cat2.jpeg';
import Cat4 from '../Images/Cats/cat4.jpeg';
import Cat5 from '../Images/Cats/cat5.jpeg';
import Cat6 from '../Images/Cats/cat6.jpeg';
import Cat7 from '../Images/Cats/cat7.jpeg';
import Cat8 from '../Images/Cats/cat9.jpeg';
import Cat9 from '../Images/Cats/cat10.jpeg';
import Cat10 from '../Images/Cats/cat11.jpeg';
import Cat11 from '../Images/Cats/cat12.jpeg';
import Cat12 from '../Images/Cats/cat13.jpeg';
import Cat13 from '../Images/Cats/cat16.jpeg';
import Cat14 from '../Images/Cats/cat17.jpeg';
import Cat15 from '../Images/Cats/cat18.jpeg';
import Cat16 from '../Images/Cats/cat19.jpeg';
import Cat17 from '../Images/Cats/cat20.jpeg';
import Cat18 from '../Images/Cats/cat21.jpeg';

class Cats extends React.Component {
    render(){
        return(
            <div style={{position:'relative',overflowX:'hidden',width:'100%'}}>
            <br/>
                <div className="row">
                    <div className="column">
                        <img alt={"Cat"} src={Cat3}/>
                        <img alt={"Cat"} src={Cat4}/>
                        <img alt={"Cat"} src={Cat9}/>
                        <img alt={"Cat"} src={Cat13}/>
                        <img alt={"Cat"} src={Cat18}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat2}/>
                        <img alt={"Cat"} src={Cat1}/>
                        <img alt={"Cat"} src={Cat10}/>
                        <img alt={"Cat"} src={Cat15}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat8}/>
                        <img alt={"Cat"} src={Cat6}/>
                        <img alt={"Cat"} src={Cat11}/>
                        <img alt={"Cat"} src={Cat14}/>
                        <img alt={"Cat"} src={Cat17}/>
                    </div>
                    <div className="column">
                        <img alt={"Cat"} src={Cat5}/>
                        <img alt={"Cat"} src={Cat7}/>
                        <img alt={"Cat"} src={Cat12}/>
                        <img alt={"Cat"} src={Cat16}/>
                    </div>
                </div>
                <br/><br/>
            </div>
        )
    }
}

export default Cats;
