import React from 'react';
import DropdownNav from './dropdown_nav.js';

class Header extends React.Component {
    // render content for header
    render(){
        return(
            <div>
                {/* large screens */}
                <div className='hidden-xs' id="menu-bar">
                    <div id="menu-items">
                        <a style={{textDecoration:'none'}} href="/"><span id="menu-item">Home</span></a>
                        <a style={{textDecoration:'none'}} href="/cats"><span id="menu-item">Cats</span></a>
                        <a style={{textDecoration:'none'}} href="/dogs"><span id="menu-item">Dogs</span></a>
                        <a style={{textDecoration:'none'}} href="/horses"><span id="menu-item">Horses</span></a>
                        <a style={{textDecoration:'none'}} href="/buildings"><span id="menu-item">Buildings and Landscapes</span></a>
                        <a style={{textDecoration:'none'}} href="/others"><span id="menu-item">Others</span></a>
                        <a style={{textDecoration:'none'}} href="/calendars"><span id="menu-item">Calendars</span></a>
                        <a style={{textDecoration:'none'}} href="/contact"><span id="menu-item">Contact</span></a>
                    </div>
                </div>

                {/* smallest screens */}
                <div style={{height:'75px'}} className='visible-xs' id="menu-bar">
                    <DropdownNav style={{float:'right'}}/>
                    <br/>
                </div>
            </div>
        )
    }
}
export default Header;