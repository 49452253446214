import React from 'react';
import Card1 from '../Images/Icons/buisinessCard1.jpeg';
import Card2 from '../Images/Icons/buisinessCard2.jpeg';
import Calendar1 from '../Images/Calendars/calendar1.gif';
import Calendar2 from '../Images/Calendars/calendar2.gif';

class About extends React.Component {
    render(){
        return(
            <div>
                <br/>
                {/* business card */}
                <div className="row">
                    <div className="column">
                    </div>
                    <div className="column">
                        <h1>Contact Me</h1>
                        <h3 style={{fontWeight:'lighter'}}>
                            <b>Telephone:</b>
                            <br/>
                            (301) 500-7494
                            <br/><br/>
                            <b>Email:</b>
                            <br/>
                            <span style={{fontSize:'smaller'}}>mitamariagironda@icloud.com</span>
                        </h3>
                    </div>
                    <div className="column">
                        <img alt={"Card"} src={Card1}/>
                    </div>
                    <div className="column">
                        <img alt={"Card"} src={Card2}/>
                    </div>
                </div>
                <br/><br/><br/>
                

                {/* calendars */}
                <div className="row">
                    <div className="column visible-xs">
                        <h1>Calendars</h1>
                        <h3 style={{fontWeight:'lighter'}}>
                            Use the contact information above to purchase
                            a calendar with paintings on it.
                        </h3>
                    </div>
                    <div className="column">
                        <img alt={"Calendar"} src={Calendar1}/>
                    </div>
                    <div className="column">
                        <img alt={"Calendar"} src={Calendar2}/>
                    </div>
                    <div className="column hidden-xs">
                        <h1>Calendars</h1>
                        <h3 style={{fontWeight:'lighter'}}>
                            Use the contact information above to purchase
                            a calendar with paintings on it.
                        </h3>
                    </div>
                    <div className="column">
                    </div>
                </div>
                <br/><br/><br/>
            </div>
        )
    }
}

export default About;